<template>
  <form class="form">

    <Autocomplete 
      :data="{name: $root.langText.input.mainCarrier, filter: 'is_main', mod: 'counterparties', key: 'main_carrier', input: data.main_carrier, inputId: data.main_carrier_id}" 
      @result="autocompleteResult" 
    />

    <Autocomplete 
      v-if="data.main_carrier_id != '0' && data.main_carrier_id != ''"
      :data="{name: $root.langText.input.carrier, filter: 'is_regular', idMainContractor: data.main_carrier_id, mod: 'counterparties', key: 'carrier', input: data.carrier, inputId: data.carrier_id}" 
      @result="autocompleteResult" 
    />


    <Autocomplete 
      :data="{
        name: $root.langText.input.managerCarrier, 
        mod: 'contact', 
        key: 'carrier_manager', 
        input: data.carrier_manager, 
        inputId: data.carrier_manager_id, 
        idClient: data.main_carrier_id, 
        typeClient: 'contractor'
      }"
      :hidden="canEdit"
      v-if="data.main_carrier"
      @result="autocompleteResult" 
    />

    <div class="form__input form__input--not-focus" v-if="data.main_carrier">
      <input type="text" readonly
        v-model="data.carrier_manager_tel"
      >
      <span>{{$root.langText.input.managerPhone}}</span>
    </div>


    <Autocomplete 
      v-if="data.main_carrier_id != '0' && data.main_carrier_id != ''"
      :data="{name: $root.langText.input.driver, mod: 'drivers', key: 'driver', idContractor: data.main_carrier_id, input: data.driver, inputId: data.driver_id}" 
      @result="autocompleteResult" 
    />


    <div class="form__input form__input--not-focus" v-if="data.main_carrier_id != '0' && data.main_carrier_id != ''">
      <input type="text" readonly
        v-model="data.driver_tel"
      >
      <span>{{$root.langText.input.driverPhone}}</span>
    </div>

    <div class="form__input" v-if="data.main_carrier_id != '0' && data.main_carrier_id != '' && contractorsTransport.length != 0">
      <select 
        v-model="data.container_type_id"
      >
        <option
          v-for="(type, index) in contractorsTransport"
          :key="index"
          :value="type.container_type"
        >{{type.container_type_name}}</option>
      </select>
      <span>{{$root.langText.changes.carType}}</span>
      <div class="icon icon-arrow-down"></div>
    </div>


    <div class="form__row form__row-50" v-if="data.main_carrier_id != '0' && data.main_carrier_id != ''">
      <Autocomplete 
        :data="{name: $root.langText.input.car, mod: 'car', idContractor: data.main_carrier_id, key: 'truck', input: data.truck, inputId: data.truck_id}" 
        @result="autocompleteResult" 
      />

      <Autocomplete 
        :data="{name: $root.langText.input.trailer, mod: 'trailers', idContractor: data.main_carrier_id, key: 'trailer', input: data.trailer, inputId: data.trailer_id}" 
        @result="autocompleteResult" 
      />
    </div>


    <div class="form__row form__row-50">
      <div class="form__input">
        <input type="number"
          v-model="data.lifting_capacity"
        >
        <span>{{$root.langText.input.liftingCapacity}}</span>
      </div>


      <div class="form__input">
        <input type="number"
          v-model="data.volume"
        >
        <span>{{$root.langText.input.volume}}</span>
      </div>
    </div>
  


    <Autocomplete 
      :data="{name: $root.langText.input.legalEntity2, mod: 'legalPersons', key: 'legalEntity', input: data.legalEntity, inputId: data.legalEntity_id}" 
      @result="autocompleteResult" 
    />


    <div class="form__input">
      <p class="form__input__textarea-p">{{data.specialConditions}}</p>
      <textarea class="form__input__textarea"
        type="text"
        v-model="data.specialConditions"
      />
      <span>{{$root.langText.input.specialConditions}}</span>
    </div>


    <div class="form__input form__input--not-focus">
      <input type="text" readonly
        v-model="data.rating"
      >
      <span>{{$root.langText.input.rating}}</span>
    </div>


    <Autocomplete 
      :data="{name: $root.langText.input.borderCrossing, mod: 'city', key: 'borderCrossing', input: data.borderCrossing, inputId: data.borderCrossing_id}" 
      @result="autocompleteResult" 
    />


    <!-- start dies -->
    <div class="block-slide-toggle"
      v-for="(accordion, index) in accordionName"
      :key="index"
    >
      <Accordion
        :application="{
          main_carrier_id: data.main_carrier_id,
          driver: data.driver, 
          driver_id: data.driver_id,
          truck: data.truck, 
          truck_id: data.truck_id,
        }" 
        :data="id" 
        :canEdit="canEdit"
        :name="accordionName[index]" 
        :carrier="data.main_carrier_id" 
        :modCreate="'quotation'" 
        :modType="2" 
        @update="$emit('updateData')" />
      <div class="decor-line"></div>
    </div>
    <!-- end dies -->


    <div class="form__checkbox">
      <label>
        <input type="checkbox"
          v-model="data.is_overload"
        >
        <span>{{$root.langText.input.overload}}</span>
      </label>
    </div>




    <div v-if="data.is_overload">
      <Autocomplete 
        :data="{name: $root.langText.input.overloadPlace, mod: 'city', key: 'overloadPlace', input: data.overloadPlace, inputId: data.overloadPlace_id}" 
        @result="autocompleteResult" 
      />


      <DataInput  :data="{data: data.date, input: 'date', name: $root.langText.input.dateTranshipment}" @resultData="dataInput"/>



      <h2>{{$root.langText.input.carrierTwo}}</h2>

      <Autocomplete 
        :data="{name: $root.langText.input.mainCarrier,  mod: 'counterparties', key: 'main_carrier2', input: data.main_carrier2, inputId: data.main_carrier2_id}" 
        @result="autocompleteResult" 
      />


      <Autocomplete 
        v-if="data.main_carrier2_id != '0' && data.main_carrier2_id != ''"
        :data="{name: $root.langText.input.carrier, filter: 'is_regular', idMainContractor: data.main_carrier2_id, mod: 'counterparties', key: 'carrier2', input: data.carrier2, inputId: data.carrier2_id}" 
        @result="autocompleteResult" 
      />


      <Autocomplete 
        :data="{
          name: $root.langText.input.managerCarrier, 
          mod: 'contact', 
          key: 'carrier_manager2', 
          input: data.carrier_manager2, 
          inputId: data.carrier_manager2_id, 
          idClient: data.main_carrier2_id, 
          typeClient: 'contractor'
        }"
        :hidden="canEdit" 
        v-if="data.main_carrier2"
        @result="autocompleteResult" 
      />

      <div class="form__input form__input--not-focus" v-if="data.main_carrier2">
        <input type="text" readonly
          v-model="data.carrier_manager2_tel"
        >
        <span>{{$root.langText.input.managerPhone}}</span>
      </div>


      <Autocomplete 
        v-if="data.main_carrier2_id != '0' && data.main_carrier2_id != ''"
        :data="{name: $root.langText.input.driver, idContractor: data.main_carrier2_id, mod: 'drivers', key: 'driver2', input: data.driver2, inputId: data.driver2_id}" 
        @result="autocompleteResult" 
      />


      <div class="form__input form__input--not-focus" v-if="data.main_carrier2_id != '0' && data.main_carrier2_id != ''">
        <input type="text" readonly
          v-model="data.driver2_tel"
        >
        <span>{{$root.langText.input.driverPhone}}</span>
      </div>
      <div class="form__input" v-if="data.main_carrier2_id != '0' && data.main_carrier2_id != '' && contractorsTransport2.length != 0">
        <select 
          v-model="data.container_type2_id"
        >
          <option
            v-for="(type, index) in contractorsTransport2"
            :key="index"
            :value="type.container_type"
          >{{type.container_type_name}}</option>
        </select>
        <span>{{$root.langText.changes.carType}}</span>
        <div class="icon icon-arrow-down"></div>
      </div>    

      <div class="form__row form__row-50" v-if="data.main_carrier2_id != '0' && data.main_carrier2_id != ''">
        <Autocomplete 
          :data="{name: $root.langText.input.car2, idContractor: data.main_carrier2_id, mod: 'car', key: 'truck2', input: data.truck2, inputId: data.truck2_id}" 
          @result="autocompleteResult" 
        />

        <Autocomplete 
          :data="{name: $root.langText.input.trailer, idContractor: data.main_carrier2_id, mod: 'trailers', key: 'trailer2', input: data.trailer2, inputId: data.trailer2_id}" 
          @result="autocompleteResult" 
        />
      </div>


      <div class="form__row form__row-50">
        <div class="form__input">
          <input type="text"
            v-model="data.lifting_capacity2"
          >
          <span>{{$root.langText.input.liftingCapacity}}</span>
        </div>


        <div class="form__input">
          <input type="text"
            v-model="data.volume2"
          >
          <span>{{$root.langText.input.volume}}</span>
        </div>
      </div>
    


      <Autocomplete 
        :data="{name: $root.langText.input.legalEntity2, mod: 'legalPersons', key: 'legalEntity2', input: data.legalEntity2, inputId: data.legalEntity2_id}" 
        @result="autocompleteResult" 
      />



      <div class="form__input form__input--not-focus">
        <input type="text" readonly
          v-model="data.rating2"
        >
        <span>{{$root.langText.input.rating}}</span>
      </div>


      <!-- start dies -->
      <div class="block-slide-toggle"
        v-for="(accordion, index) in accordionName2"
        :key="index"
      >
        <Accordion 
          :application="{
            main_carrier_id: data.main_carrier2_id,
            driver: data.driver2, 
            driver_id: data.driver2_id,
            truck: data.truck2, 
            truck_id: data.truck2_id,
          }"
          :canEdit="canEdit"
          :data="id" 
          :name="accordionName2[index]" 
          :carrier="data.main_carrier2_id" 
          :modCreate="'quotation'" 
          :modType="2" 
          @update="popupClose" />
        <div class="decor-line"></div>
      </div>
      <!-- end dies -->
    
    </div>



    <!-- start dies -->
      <div class="block-slide-toggle"
        v-for="(accordion, index) in accordionName3"
        :key="accordion.bd_name"
        style="margin-bottom: 20px"
      >
        <Accordion 
          :application="[
            {name: data.main_carrier, id: data.main_carrier_id},
            {name: data.carrier, id: data.carrier_id},
            {name: data.main_carrier2, id: data.main_carrier2_id},
            {name: data.carrier2,id: data.carrier2_id}
          ]"
          :canEdit="canEdit"
          :data="id" 
          :name="accordionName3[index]" 
          :modCreate="'quotation'" 
          :modType="2" 
          @update="popupClose" />
      </div>
      <!-- end dies -->

    <a class="btn-save btn btn-primary" v-if="canEdit" @click.prevent="sendForm">{{$root.langText.save}}</a>     
    <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
  </form>
</template>






<script>
import Accordion from '@/components/agreements/Accordion'
import Autocomplete from "@/components/app/Autocomplete"
import Message from "@/components/app/Message"
import DataInput from '@/components/app/DataInput'

export default {
  props: ['id'],
  data: () => ({
    agreementsId: null,
    canEdit: true,
    data: {
      main_carrier: '',
      main_carrier_id: '',
      main_carrier2: '',
      main_carrier2_id: '',

      carrier: '',
      carrier_id: '',
      carrier2: '',
      carrier2_id: '',

      carrier_manager: '',
      carrier_manager_id: '',
      carrier_manager2: '',
      carrier_manager2_id: '',
      
      carrier_manager_tel: '',
      carrier_manager2_tel: '',

      driver: '',
      driver_id: '',
      driver2: '',
      driver2_id: '',

      driver_tel: '',
      driver2_tel: '',

      container_type: '',
      container_type_id: '',
      container_type2: '',
      container_type2_id: '',

      truck: '',
      truck_id: '',
      truck2: '',
      truck2_id: '',

      trailer: '',
      trailer_id: '',
      trailer2: '',
      trailer2_id: '',

      lifting_capacity: '',
      lifting_capacity2: '',

      volume: '',
      volume2: '',

      legalEntity: '',
      legalEntity_id: '',
      legalEntity2: '',
      legalEntity2_id: '',

      specialConditions: '',
      specialConditions2: '',

      rating: '',
      rating2: '',

      borderCrossing: '',
      borderCrossing_id: '',

      is_overload: 0,

      overloadPlace: '',
      overloadPlace_id: '',

      date: '',

    },


    is_carrier_manager: false,
    is_carrier_manager2: false,
    accordionName: [
      // {name: 'Контрагенти', mode: 'contractors', bd_name: 'contractors'},
      {name: 'Фрахт перевозчика', mode: 'freight', bd_name: 'carrier_freight'},
      {name: 'ОТК перевозчика', mode: 'kickbacks', bd_name: 'kickbacks_carrier'},
      // {name: 'Заявка від перевозчика', mode: 'requests', bd_name: 'requests_carrier'},
      // {name: 'Додаткові витрати', mode: '', bd_name: 'additional_expenses'},
    ],

    contractorsTransport: '',
    contractorsTransport2: '',

    accordionName2: [
      {name: 'Фрахт перевозчика 2', mode: 'freight', bd_name: 'carrier_freight2'},
      {name: 'ОТК перевозчика 2', mode: 'kickbacks', bd_name: 'kickbacks_carrier2'},
      // {name: 'Заявка від перевозчика 2', mode: 'requests', bd_name: 'requests_carrier2'},
      // {name: 'Додаткові витрати', mode: '', bd_name: 'additional_expenses'},
    ],
    accordionName3: [
      {name: 'Заявки', mode: 'requests', bd_name: 'requests_carrier'},
    ],


    //message
    message: {
      status: '',
      popup: false,
      message: ''
    },
  }),



  
  async created() {
    function boolean(val){
      return (val == 0) ? (false) : true
    }
    this.accordionName[0].name = this.$root.langText.carrierFreight
    this.accordionName[1].name = this.$root.langText.kickbacksCarrier
    this.accordionName2[0].name = this.$root.langText.carrierFreight + ' 2'
    this.accordionName2[1].name = this.$root.langText.kickbacksCarrier + ' 2'
    this.accordionName3[0].name = this.$root.langText.requests




    if(this.$route.params.id){
      this.canEdit = this.id.canEdit

      if(this.$root.add.is_dispatcher){
        this.accordionName = []
        this.accordionName2 = []
        this.accordionName3 = []
      }
      if(this.$root.data.order.carrier){
        this.agreementsId = this.$root.data.order.carrier
      } else{
        this.agreementsId = this.id
      }

      //data
      this.data.main_carrier = this.agreementsId.main_carrier_name
      this.data.main_carrier_id = this.agreementsId.main_carrier_id
      this.data.main_carrier2 = this.agreementsId.main_carrier2_name
      this.data.main_carrier2_id = this.agreementsId.main_carrier2_id

      this.data.carrier = this.agreementsId.carrier_name
      this.data.carrier_id = this.agreementsId.carrier_id
      this.data.carrier2 = this.agreementsId.carrier2_name
      this.data.carrier2_id = this.agreementsId.carrier2_id

      this.data.carrier_manager = this.agreementsId.carrier_manager_name
      this.data.carrier_manager_id = this.agreementsId.carrier_manager_id
      this.data.carrier_manager2 = this.agreementsId.carrier_manager2_name
      this.data.carrier_manager2_id = this.agreementsId.carrier_manager2_id
      if(this.agreementsId.carrier_manager_name){
        this.is_carrier_manager = true
      }
      if(this.agreementsId.carrier_manager2_name){
        this.is_carrier_manager2 = true
      }

      this.data.carrier_manager_tel = this.agreementsId.carrier_manager_phone
      this.data.carrier_manager2_tel = this.agreementsId.carrier_manager2_phone

      this.data.container_type = this.agreementsId.container_type_name
      this.data.container_type_id = this.agreementsId.container_type_id
      this.data.container_type2 = this.agreementsId.container_type2_name
      this.data.container_type2_id = this.agreementsId.container_type2_id

      this.data.driver = this.agreementsId.driver_name
      this.data.driver_id = this.agreementsId.driver_id
      this.data.driver2 = this.agreementsId.driver_name2
      this.data.driver2_id = this.agreementsId.driver_id2

      this.data.driver_tel = this.agreementsId.driver_phone
      this.data.driver2_tel = this.agreementsId.driver_phone2

      this.data.truck = this.agreementsId.truck_name
      this.data.truck_id = this.agreementsId.truck_id
      this.data.truck2 = this.agreementsId.truck_name2
      this.data.truck2_id = this.agreementsId.truck_id2

      this.data.trailer = this.agreementsId.trailer_name
      this.data.trailer_id = this.agreementsId.trailer_id
      this.data.trailer2 = this.agreementsId.trailer_name2
      this.data.trailer2_id = this.agreementsId.trailer_id2

      this.data.lifting_capacity = this.agreementsId.lifting_capacity
      this.data.lifting_capacity2 = this.agreementsId.lifting_capacity2

      this.data.volume = this.agreementsId.volume
      this.data.volume2 = this.agreementsId.volume2

      this.data.legalEntity = this.agreementsId.legal_entity_name
      this.data.legalEntity_id = this.agreementsId.legal_entity_id
      this.data.legalEntity2 = this.agreementsId.legal_entity2_name
      this.data.legalEntity2_id = this.agreementsId.legal_entity2_id

      this.data.specialConditions = this.agreementsId.special_conditions

      this.data.rating = this.id.rate_carrier
      this.data.rating2 = this.id.rate_carrier2

      this.data.borderCrossing = this.agreementsId.border_crossing_name
      this.data.borderCrossing_id = this.agreementsId.border_crossing_id

      this.data.is_overload = boolean(this.agreementsId.is_overload);

      this.data.overloadPlace = this.agreementsId.overload_city_name
      this.data.overloadPlace_id = this.agreementsId.overload_city_id

      this.contractorsTransport = this.agreementsId.transport_companies
      this.contractorsTransport2 = this.agreementsId.transport_companies2

      if(this.agreementsId.overload_date){
        this.data.date = this.agreementsId.overload_date
      }else{
        this.data.date = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date())
      }
    }

  },
  methods: {
    //autocomplete
    async autocompleteResult(data){
      this.data[data.input] = data.name
      this.data[data.input + '_id'] = data.id
      if(data.input == 'main_carrier'){
        this.data.carrier = ''
        this.data.carrier_id = ''
        this.data.carrier_manager = ''
        this.data.carrier_manager_id = ''
        this.data.carrier_manager_tel = ''
        this.data.driver = ''
        this.data.driver_id = ''
        this.data.driver_tel = ''
        this.data.truck = ''
        this.data.truck_id = ''
        this.data.trailer = ''
        this.data.trailer_id = ''
        if(data.id){
          var id = data.id
          var filter = 'is_unique=1'
          const res = await this.$store.dispatch('contractorsTransport', {filter, id});
          this.contractorsTransport = res.data.list
          if(res.data.list.length == 0){
            this.data.container_type_id = ''
          }
        }
         else{
          this.data.container_type_id = ''
        }

      }
      if(data.input == 'main_carrier2'){
        this.data.carrier2 = ''
        this.data.carrier2_id = ''
        this.data.carrier_manager2 = ''
        this.data.carrier_manager2_id = ''
        this.data.carrier_manager2_tel = ''
        this.data.driver2 = ''
        this.data.driver2_id = ''
        this.data.driver2_tel = ''
        this.data.truck2 = ''
        this.data.truck2_id = ''
        this.data.trailer2 = ''
        this.data.trailer2_id = ''
        if(data.id){
          var id = data.id
          var filter = 'is_unique=1'
          const res = await this.$store.dispatch('contractorsTransport', {filter, id});
          this.contractorsTransport = res.data.list
          if(res.data.list.length == 0){
            this.data.container_type2_id = ''
          }
        } else{
          this.data.container_type2_id = ''
        }
      }
      if(data.input == 'driver'){
        if(data.name == ''){
          this.data.driver_tel = ''
        }
      }
      if(data.input == 'driver2'){
        if(data.name == ''){
          this.data.driver2_tel = ''
        }
      }
      if(data.input == 'carrier'){
        var id = data.id
        var type = 'contractor'
        var contact = await this.$store.dispatch('getContactsPersons', {id, type})
        if(contact.data.list.length !== 0){
          this.is_carrier_manager = true
        } else{
          this.is_carrier_manager = false
        }
      }
      if(data.input == 'carrier2'){
        var id = data.id
        var type = 'contractor'
        var contact = await this.$store.dispatch('getContactsPersons', {id, type})
        if(contact.data.list.length !== 0){
          this.is_carrier_manager2 = true
        } else{
          this.is_carrier_manager2 = false
        }
      }
      if(data.phone){
        this.data[data.input + '_tel'] = data.phone
      }
    },

    //date
    dataInput(data){
      this.data[data.input] = data.date
    },
    async popupClose(){
      // var data = {
      //   id: this.agreementsId.id,
      //   carrier_id: this.data.carrier_id || '',
      // }
      // if(this.data.is_overload){
      //   data.carrier2_id = this.data.carrier2_id || ''
      // }
      // var update = await this.$store.dispatch('updateOrders', data);
      this.$emit('updateData')
    },


    async sendForm(){
      function boolean(val){
        return (val == false) ? 0 : 1
      }
      //format date
      function date(date){
        var year = date.substr(6, 4);
        var day = date.substr(0,2);
        var month = date.substr(3,2);
        var resultDate = new Date(year + "-" + month + "-" + day).getTime() / 1000;
        return resultDate
      }


      var data = {
        main_carrier_id: this.data.main_carrier_id || '',
        carrier_id: this.data.carrier_id && this.data.carrier_id !== '0' ? this.data.carrier_id : this.data.main_carrier_id,
        carrier_manager_id: this.data.carrier_manager_id || '',
        driver_id: this.data.driver_id || '',
        container_type_id: this.data.container_type_id || '',
        truck_id: this.data.truck_id || '',
        trailer_id: this.data.trailer_id || '',
        lifting_capacity: this.data.lifting_capacity || '',
        volume: this.data.volume || '',
        legal_entity_id: this.data.legalEntity_id || '',
        special_conditions: this.data.specialConditions || '',
        rate_carrier: this.data.rating || '',
        border_crossing_id: this.data.borderCrossing_id || '',
        is_overload: boolean(this.data.is_overload) || '',
      }
      if(this.data.is_overload){
        data.overload_city_id = this.data.overloadPlace_id || ''
        data.overload_date = date(this.data.date) || ''

        data.main_carrier2_id = this.data.main_carrier2_id || ''
        data.carrier2_id = this.data.carrier2_id && this.data.carrier2_id !== '0' ? this.data.carrier2_id : this.data.main_carrier2_id,
        data.carrier_manager2_id = this.data.carrier_manager2_id || ''
        data.container_type2_id = this.data.container_type2_id || '',
        data.driver_id2 = this.data.driver2_id || ''
        data.truck_id2 = this.data.truck2_id || ''
        data.trailer_id2 = this.data.trailer2_id || ''
        data.lifting_capacity2 = this.data.lifting_capacity2 || ''
        data.volume2 = this.data.volume2 || ''
        data.legal_entity2_id = this.data.legalEntity2_id || ''
        data.rate_carrier2 = this.data.rating2 || ''
      }




      if(this.$route.params.id){
        data.id = this.agreementsId.id
        if(this.$root.data.order.info){
          var old = this.$root.data.order.info.created_at
          this.$root.data.order.info.created_at = this.$root.data.order.info.created_at ? new Date(this.$root.data.order.info.created_at).getTime() / 1000 : ''
          await this.$store.dispatch('updateQuotations', this.$root.data.order.info);
          this.$root.data.order.info.created_at = old
        }
        if(this.$root.data.order.customer){
          await this.$store.dispatch('updateQuotations', this.$root.data.order.customer);
        }
        var update = await this.$store.dispatch('updateQuotations', data);
        this.$root.data.counterparties.info = ''
        this.$root.data.counterparties.customer = ''
        this.$root.data.counterparties.carrier = ''
        this.$emit('updateData')

        
        //messedge
        if(update.data == 'ok'){
          this.message.status = update.data
          this.message.popup = true
          this.message.message = this.$root.langText.changes.save
        } else{
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.error
        }
      }
      
    }
  },

  beforeDestroy() {
    function boolean(val){
      return (val == false) ? 0 : 1
    }
    //format date
    function date(date){
      var year = date.substr(6, 4);
      var day = date.substr(0,2);
      var month = date.substr(3,2);
      var resultDate = new Date(year + "-" + month + "-" + day).getTime() / 1000;
      return resultDate
    }

    var data = {
      main_carrier_id: this.data.main_carrier_id || '',
      main_carrier_name: this.data.main_carrier || '',
      carrier_id: this.data.carrier_id || '',
      carrier_name: this.data.carrier || '',
      carrier_manager_id: this.data.carrier_manager_id || '',
      carrier_manager_name: this.data.carrier_manager || '',
      carrier_manager_phone: this.data.carrier_manager_tel || '',
      driver_id: this.data.driver_id || '',
      driver_name: this.data.driver || '',
      container_type_name: this.data.container_type || '',
      container_type_id: this.data.container_type_id || '',
      truck_id: this.data.truck_id || '',
      truck_name: this.data.truck || '',
      trailer_id: this.data.trailer_id || '',
      trailer_name: this.data.trailer || '',
      lifting_capacity: this.data.lifting_capacity || '',
      volume: this.data.volume || '',
      legal_entity_id: this.data.legalEntity_id || '',
      legal_entity_name: this.data.legalEntity || '',
      special_conditions: this.data.specialConditions || '',
      rate_carrier: this.data.rating || '',
      border_crossing_id: this.data.borderCrossing_id || '',
      border_crossing_name: this.data.borderCrossing || '',
      is_overload: boolean(this.data.is_overload) || '',
      driver_phone: this.data.driver_tel || '',
      driver_phone2: this.data.driver2_tel || '',
      transport_companies: this.contractorsTransport || [],
    }

    data.overload_city_id = this.data.overloadPlace_id || ''
    data.overload_city_name = this.data.overloadPlace || ''
    if(this.data.date){
      var date = new Date(date(this.data.date) * 1000);
      var newdate = date.getMonth()+1 + '.' + date.getDate() + '.' + date.getFullYear();
      data.overload_date = newdate
    }

    data.main_carrier2_id = this.data.main_carrier2_id || ''
    data.main_carrier2_name = this.data.main_carrier2 || ''
    data.carrier2_id = this.data.carrier2_id || ''
    data.carrier2_name = this.data.carrier2 || ''
    data.carrier_manager2_id = this.data.carrier_manager2_id || ''
    data.carrier_manager2_name = this.data.carrier_manager2 || ''
    data.carrier_manager2_phone = this.data.carrier_manager2_tel || ''
    data.driver_id2 = this.data.driver2_id || ''
    data.driver_name2 = this.data.driver2 || ''
    data.container_type2_name = this.data.container_type || '',
    data.container_type2_id = this.data.container_type_id || '',
    data.truck_id2 = this.data.truck2_id || ''
    data.truck_name2 = this.data.truck2 || ''
    data.trailer_id2 = this.data.trailer2_id || ''
    data.trailer_name2 = this.data.trailer2 || ''
    data.lifting_capacity2 = this.data.lifting_capacity2 || ''
    data.legal_entity2_name = this.data.legalEntity2 || ''
    data.volume2 = this.data.volume2 || ''
    data.legal_entity2_id = this.data.legalEntity2_id || ''
    data.rate_carrier2 = this.data.rating2 || ''
    data.transport_companies2 = this.contractorsTransport2 || []

    if(this.id){
      data.id = this.id.id
    }
    this.$root.data.order.carrier = data


  },


  components: {
    Autocomplete, Message, DataInput, Accordion
  }
}
</script>