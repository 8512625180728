<template>
  <div>
    <form class="form">
      <h2>{{$root.langText.info}}</h2>

      <div class="form__row" :class="$route.params.id ? 'form__row-70' : ''">
        <div class="form__input" v-if="$route.params.id">
          <input 
            type="text" disabled
            v-model="fields.number"
          >
          <span>{{$root.langText.input.number}}</span>
        </div>

        
        <div class="form__input">
          <input 
            type="text"
            :disabled="$route.params.id"
            v-model="fields.date" 
          >
          <span>{{$root.langText.input.date}}</span>
        </div>
      </div>

      <div class="form__input" v-if="$route.params.id">
        <input type="text" disabled v-model="fields.creator_name" />
        <span>{{$root.langText.input.author}}</span>
      </div>

      <div class="form__input">
        <select v-model="fields.status_id">
          <option
            v-for="(type, index) in status"
            :key="index"
            :value="type.id"
          >{{type.name}}</option>
          
        </select>
        <span>{{$root.langText.input.status}}</span>
        <div class="icon icon-arrow-down"></div>
      </div>


      <Autocomplete 
        :data="{name: $root.langText.input.client, mod: 'clients', key: 'client', input: fields.client, inputId: fields.client_id}" 
        @result="autocompleteResult" 
      />


      <div class="form__row form__row-70">
        <div class="form__input">
          <select v-model="fields.type_id">
            <option
              v-for="(type, index) in types"
              :key="index"
              :value="type.id"
            >{{type.name}}</option>
            
          </select>
          <span>{{$root.langText.input.type}}</span>
          <div class="icon icon-arrow-down"></div>
        </div>

        
        <div class="form__input">
          <select v-model="fields.select">
            <option
              v-for="(select, index) in selects"
              :key="index"
            :value="select.id">{{select.name}}</option>
            
          </select>
          <span>{{$root.langText.input.view}}</span>
          <div class="icon icon-arrow-down"></div>
        </div>
      </div>

      <div v-if="quotationsId && $route.params.id">
        <a @click.prevent="puschOrders" v-if="quotationsId.order_id == 0 && this.$root.add.can_add_order" class="btn-save btn btn-primary" style="margin-bottom: 20px">{{$root.langText.changes.orders}}</a>
        <a @click.prevent="puschOrders2" v-if="quotationsId.order_id !== '0' && quotationsId.order_id !== ''" class="btn-save btn btn-primary" style="margin-bottom: 20px">{{$root.langText.directory.orderOtherOne}} #{{quotationsId.order_number}} {{$root.langText.from}} {{quotationsId.order_date | date}}</a>
      </div>


      <Autocomplete 
        :data="{name: $root.langText.input.conditions, mod: 'all', modType: 'conditions', key: 'conditions', input: fields.conditions, inputId: fields.conditions_id}" 
        @result="autocompleteResult" 
      />
      <Autocomplete 
        :data="{name: $root.langText.input.cargo, mod: 'all', modType: 'cargo', key: 'cargo', input: fields.cargo, inputId: fields.cargo_id}" 
        @result="autocompleteResult" 
      />

      <Autocomplete 
        :data="{name: $root.langText.changes.container, mod: 'carType', key: 'container_type', input: fields.container_type, inputId: fields.container_type_id}" 
        @result="autocompleteResult" 
      />

      <Autocomplete 
        :data="{name: $root.langText.route, mod: 'all', modType: 'route', key: 'route', input: fields.route, inputId: fields.route_id}" 
        @result="autocompleteResult" 
      />

      <DataInput
        :data="{ data: fields.readiness, input: 'readiness', name: $root.langText.input.valid_to}"
        @resultData="dataInput"
      />

      <DataInput
        :data="{ data: fields.valid_to, input: 'valid_to', name: $root.langText.input.ready}"
        @resultData="dataInput"
      />

      <!-- NOT Vedanta -->
      <div v-if="$root.app !== 'SoftProCrmAppVedanta'">
        <h2>{{$root.langText.input.deliveryWay}}</h2>

  
          <Autocomplete
            :data="{
              name: $root.langText.input.poo,
              mod: 'city',
              key: 'poo',
              input: fields.poo,
              inputId: fields.poo_id,
              img: id.poo_country_code,
            }"
            @result="autocompleteResult"
          />


          <Autocomplete
            :data="{
              name: $root.langText.input.pol,
              mod: 'city',
              key: 'pol',
              input: fields.pol,
              inputId: fields.pol_id,
              img: id.pol_country_code,
            }"
            @result="autocompleteResult"
          />



          <Autocomplete
            :data="{
              name: $root.langText.input.pod,
              mod: 'city',
              key: 'pod',
              input: fields.pod,
              inputId: fields.pod_id,
              img: id.pod_country_code,
            }"
            @result="autocompleteResult"
          />


       
          <Autocomplete
            :data="{
              name: $root.langText.input.fd,
              mod: 'city',
              key: 'fd',
              input: fields.fd,
              inputId: fields.fd_id,
              img: id.fd_country_code,
            }"
            @result="autocompleteResult"
          />

      </div>
      <!-- END NOT Vedanta -->


      <h2>{{$root.langText.input.employeesResponsible}}</h2>
      <Autocomplete 
        :data="{name: $root.langText.input.manager, mod: 'team', key: 'manager', input: fields.manager, inputId: fields.manager_id}" 
        @result="autocompleteResult" 
      />


      <Autocomplete 
        :data="{name: $root.langText.input.operative, mod: 'team', key: 'op_manager', input: fields.op_manager, inputId: fields.op_manager_id}" 
        @result="autocompleteResult" 
      />


    <Autocomplete 
      :data="{name: $root.langText.input.legalEntity2, mod: 'legalPersons', key: 'legalEntity', input: fields.legalEntity, inputId: fields.legalEntity_id}" 
      @result="autocompleteResult" 
    />


    <div class="form__input">
      <p class="form__input__textarea-p">{{fields.comments}}</p>
      <textarea class="form__input__textarea"
        type="text"
        v-model="fields.comments"
      />
      <span>{{$root.langText.input.note}}</span>
    </div>

    <div class="form__input">
      <p class="form__input__textarea-p">{{fields.specialConditions}}</p>
      <textarea class="form__input__textarea"
        type="text"
        v-model="fields.specialConditions"
      />
      <span>{{$root.langText.input.specialConditions}}</span>
    </div>




      <div style="display: flex; justify-content: space-between" v-if="$route.params.id">
        <a
          class="btn btn-secondary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('copy')"
          >{{ $root.langText.copy }}</a
        >

        <a
          class="btn btn-primary"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
      <div v-else>
        <a
          class="btn btn-primary btn-save"
          v-if="canEdit"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>
      <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
    </form>
  </div>
</template>




<script>
import Autocomplete from "@/components/app/Autocomplete"
import Message from "@/components/app/Message"
import DataInput from "@/components/app/DataInput";
import { TheMask } from "vue-the-mask";


export default {
  props: ['id'],
  data: () => ({
    canEdit: true,
    quotationsId: null,

    fields: {
      id: '',
      number: '',
      date: '',
      creator_name: '',
      status: '',
      client: '',
      client_id: '',
      type_id: '',
      select: '',
      transit: '',

      conditions: '',
      conditions_id: '',

      cargo: '',
      cargo_id: '',

      container_type: '',
      container_type_id: '',

      route: '',
      route_id: '',

      readiness: '',
      valid_to: '',

      poo: "",
      poo_id: "",

      pol: "",
      pol_id: "",

      pod: "",
      pod_id: "",

      fd: "",
      fd_id: "",


      manager: '',
      manager_id: '',
      op_manager: '',
      op_manager_id: '',
      type_direction_color: '#8EB6E3',

      lifting_capacity: '',

      volume: '',


      legalEntity: '',
      legalEntity_id: '',
      comments: '',

      specialConditions: '',
      status_id: '',
    },

    selects: [],

    types: [],
    status: [],
    //message
    message: {
      status: '',
      popup: false,
      message: ''
    },

    //select type
    params: {
      start: 0, 
      limit: 20,
    },
  }), 
  async created() {
    if(this.$route.params.id){
      
      this.canEdit = this.id.canEdit
      this.quotationsId = this.id
      this.fields.id = this.quotationsId.id
      this.fields.creator_name = this.quotationsId.creator_name
      this.fields.status_id = this.quotationsId.status_id
      this.fields.number = this.quotationsId.number
      this.fields.date = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date(this.quotationsId.created_at.replace(/-/g, "/")))
      this.fields.client = this.quotationsId.client_name
      this.fields.client_id = this.quotationsId.client_id
      this.fields.type_id = this.quotationsId.order_type_id
      this.fields.type_direction_color = this.quotationsId.type_direction_color
      this.fields.select = this.quotationsId.order_type_direction_id 
      // this.fields.transit = this.quotationsId.manager_name

      this.fields.conditions = this.quotationsId.incoterm_name
      this.fields.conditions_id = this.quotationsId.incoterm_id 

      this.fields.cargo = this.quotationsId.cargo_name
      this.fields.cargo_id = this.quotationsId.cargo_id 

      this.fields.container_type = this.quotationsId.container_type_name
      this.fields.container_type_id = this.quotationsId.container_type_id 

      this.fields.route = this.quotationsId.route_name
      this.fields.route_id = this.quotationsId.route_id 

      this.fields.readiness = this.quotationsId.cargo_ready 
      this.fields.valid_to = this.quotationsId.valid_to 

      this.fields.poo = this.quotationsId.poo_name;
      this.fields.poo_id = this.quotationsId.poo_id;
      this.fields.pooData = this.quotationsId.date_of_poo;

      this.fields.pol = this.quotationsId.pol_name;
      this.fields.pol_id = this.quotationsId.pol_id;
      this.fields.polData = this.quotationsId.date_of_pol;

      this.fields.pod = this.quotationsId.pod_name;
      this.fields.pod_id = this.quotationsId.pod_id;
      this.fields.podData = this.quotationsId.date_of_pod;

      this.fields.fd = this.quotationsId.fd_name;
      this.fields.fd_id = this.quotationsId.fd_id;
      this.fields.fdData = this.quotationsId.date_of_fd;



      this.fields.manager = this.quotationsId.manager_name
      this.fields.manager_id = this.quotationsId.manager_id
      this.fields.op_manager = this.quotationsId.op_manager_name
      this.fields.op_manager_id = this.quotationsId.op_manager_id


      this.fields.lifting_capacity = this.quotationsId.lifting_capacity


      this.fields.volume = this.quotationsId.volume

      this.fields.legalEntity = this.quotationsId.legal_entity_name
      this.fields.legalEntity_id = this.quotationsId.legal_entity_id

      this.fields.specialConditions = this.quotationsId.special_conditions
      this.fields.comments = this.quotationsId.comment

      

      
    }else{
      this.fields.date = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date())
    }


    if(this.$root.status.quotation){
      this.status = this.$root.status.quotation
    } else{
      const res2 = await this.$store.dispatch('getStatusQ');
      this.status = res2.data.list
      this.$root.status.quotation = res2.data.list
    }
    if(this.$root.type){
      this.types = this.$root.type
    } else{
      const res = await this.$store.dispatch('getOrdersTypes', this.params);
      this.types = res.data.list
      this.$root.type = res.data.list
    }
    if(this.$root.view){
      this.selects = this.$root.view
    } else{
      const res3 = await this.$store.dispatch('orderType');
      this.selects = res3.data.list
      this.$root.view = res3.data.list
    }
  }, 
  mounted() {
  },
  methods: {

    //autocomplete
    autocompleteResult(data){
      this.fields[data.input] = data.name
      this.fields[data.input + '_id'] = data.id
    },


    //date
    dataInput(data) {
      this.fields[data.input] = data.date;
    },

    //pusch orders
    puschOrders(){
      this.$root.dataFilling = this.quotationsId
      this.$root.quote_id = this.quotationsId.id
      this.$router.push('/orders/new')
    },

    puschOrders2(){
      this.$router.push({name: 'AgreementsCreateId', params: {id: this.quotationsId.order_id}})
    },

    

    async sendForm(mode){
      function date(date){
        if(date){
          var year = date.substr(6, 4);
          var day = date.substr(0,2);
          var month = date.substr(3,2);
          var resultDate = new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate
        }
      }
      const currency = await this.$store.dispatch('getCurrency', {start: 0, limit: 50,});

      var data = {
        user_name: this.fields.client || '',
        client_id: this.fields.client_id || '',
        order_type_id: this.fields.type_id,
        order_type_direction_id: this.fields.select || '',
        // this.fields.resident = manager_name

        incoterm_id: this.fields.conditions_id || '',
        cargo_id: this.fields.cargo_id || '',
        container_type_id: this.fields.container_type_id || '',
        route_id: this.fields.route_id || '',

        cargo_ready: date(this.fields.readiness) || '',
        valid_to: date(this.fields.valid_to) || '',

        poo_id: this.fields.poo_id || "",
        pol_id: this.fields.pol_id || "",
        pod_id: this.fields.pod_id || "",
        fd_id: this.fields.fd_id || "",


        manager_name: this.fields.manager || '',
        manager_id: this.fields.manager_id || '',
        op_manager_name: this.fields.op_manager || '',
        op_manager_id: this.fields.op_manager_id || '',

        lifting_capacity: this.fields.lifting_capacity || '',
        volume: this.fields.volume || '',
        legal_entity_id: this.fields.legalEntity_id || '',
        special_conditions: this.fields.specialConditions || '',
        comment: this.fields.comments || '',
        status_id: this.fields.status_id || '',
        
        total_sums_currency_id: currency.data.list[0].id
      }


      if(this.$route.params.id && mode !== "copy"){
        data.id = this.quotationsId.id
        var update = await this.$store.dispatch('updateQuotations', data);
        this.$emit('updateData')
        //messedge
        if(update.data == 'ok'){
          this.message.status = update.data
          this.message.popup = true
          this.message.message = this.$root.langText.changes.save
        } else{
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.error
        }
      } else{
        if(mode !== 'copy'){
          var create = await this.$store.dispatch('createQuotations', data)
          var newId = create.data.id;
        } else{
          this.id.id = 0;
          this.quotationsId.order_id = '';
          this.fields.date = new Intl.DateTimeFormat('ru', {day: "2-digit", month: "2-digit", year: "numeric"}).format(new Date());
          this.$router.push('/quotes/new');
          return
        }

        if(create.data.status == 'error'){
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.fillIn
        } else{
          if (this.quotationsId && this.quotationsId.quote_services && this.quotationsId.quote_services.length > 0) {
            for (const item of this.quotationsId.quote_services) {
              var data_serv = item;
              delete data_serv.id;
              delete data_serv.quotes_id;
              data_serv.quotes_id = create.data.id;
              await this.$store.dispatch("servicesAddQuotations", data_serv);
              data_serv = [];
            }
          }
          

          this.$router.push({
            name: "QuotationsCreateId",
            params: { id: create.data.id },
          });
        }
      }
    }
  },
  components:{
    Autocomplete, Message, DataInput, TheMask
  }
}
</script>


