var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(
      _vm.$root.app == 'SoftProCrmAppRioTransTest' ||
      _vm.$root.app == 'SoftProCrmAppRioTrans'
    )?_c('div',[(_vm.quotationsId)?_c('div',{staticClass:"tabs",class:_vm.status ? '' : 'hide'},[(_vm.$route.params.id)?_c('ul',{staticClass:"tabs__caption"},[_c('li',{class:[_vm.activetab === 1 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 1}}},[_vm._v(" "+_vm._s(_vm.$root.langText.general)+" ")]),(!_vm.isFreight)?_c('li',{class:[_vm.activetab === 2 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 2}}},[_vm._v(" "+_vm._s(_vm.$root.langText.input.client)+" ")]):_vm._e(),(!_vm.isFreight)?_c('li',{class:[_vm.activetab === 3 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 3}}},[_vm._v(" "+_vm._s(_vm.$root.langText.input.carrier)+" ")]):_vm._e(),(_vm.quotationsId && _vm.isFreight)?_c('li',{class:[_vm.activetab === 2 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 2}}},[_vm._v(" "+_vm._s(_vm.$root.langText.input.titleService)+" "),(
              _vm.quotationsId.quote_services &&
              _vm.quotationsId.quote_services.length !== '0'
            )?_c('span',[_vm._v("("+_vm._s(_vm.quotationsId.quote_services.length)+")")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.activetab === 1 && _vm.quotationsId !== null)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsInfo1',{attrs:{"id":_vm.quotationsId},on:{"auto":_vm.autoUpdate,"updateData":_vm.updateData}})],1):_vm._e(),(_vm.isFreight)?_c('div',[(_vm.activetab === 2)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsServices',{attrs:{"id":_vm.quotationsId},on:{"updateData":_vm.updateData}})],1):_vm._e()]):_c('div',[(_vm.activetab === 2)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsClient1',{attrs:{"id":_vm.quotationsId},on:{"updateData":_vm.updateData}})],1):_vm._e(),(_vm.activetab === 3)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsCarrier1',{attrs:{"id":_vm.quotationsId},on:{"updateData":_vm.updateData}})],1):_vm._e()]),(_vm.update)?_c('a',{staticClass:"add-chat",on:{"click":function($event){$event.preventDefault();return _vm.push()}}}):_vm._e()]):_vm._e()]):_c('div',[(_vm.quotationsId)?_c('div',{staticClass:"tabs",class:_vm.status ? '' : 'hide'},[(_vm.$route.params.id)?_c('ul',{staticClass:"tabs__caption"},[_c('li',{class:[_vm.activetab === 1 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 1}}},[_vm._v(" "+_vm._s(_vm.$root.langText.general)+" ")]),(_vm.quotationsId && _vm.$root.app !== 'SoftProCrmAppVedanta')?_c('li',{class:[_vm.activetab === 2 ? 'active' : ''],on:{"click":function($event){_vm.activetab = 2}}},[_vm._v(" "+_vm._s(_vm.$root.langText.input.titleService)+" "),(
              _vm.quotationsId.quote_services &&
              _vm.quotationsId.quote_services.length !== '0'
            )?_c('span',[_vm._v("("+_vm._s(_vm.quotationsId.quote_services.length)+")")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.activetab === 1 && _vm.quotationsId !== null)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsInfoOther',{attrs:{"id":_vm.quotationsId},on:{"updateData":_vm.updateData}})],1):_vm._e(),(_vm.activetab === 2)?_c('div',{staticClass:"tabs__content"},[_c('QuotationsServices',{attrs:{"id":_vm.quotationsId},on:{"updateData":_vm.updateData}})],1):_vm._e(),(_vm.update)?_c('a',{staticClass:"add-chat",on:{"click":function($event){$event.preventDefault();return _vm.push()}}}):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }