var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"form"},[_c('h2',[_vm._v(_vm._s(_vm.$root.langText.info))]),_c('div',{staticClass:"form__row",class:_vm.$route.params.id ? 'form__row-70' : ''},[(_vm.$route.params.id)?_c('div',{staticClass:"form__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.number),expression:"fields.number"}],attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.fields.number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "number", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.number))])]):_vm._e(),_c('div',{staticClass:"form__input--plagin"},[_c('DataInput',{attrs:{"data":{
            data: _vm.fields.date,
            input: 'date',
            edit: _vm.$root.edit.can_edite_data_orders,
            name: _vm.$root.langText.input.date,
          }},on:{"resultData":_vm.dataInput}})],1)]),(_vm.$route.params.id)?_c('div',{staticClass:"form__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.creator_name),expression:"fields.creator_name"}],attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.fields.creator_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "creator_name", $event.target.value)}}}),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.author))])]):_vm._e(),_c('div',{staticClass:"form__input"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.status_id),expression:"fields.status_id"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fields, "status_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.status),function(type,index){return _c('option',{key:index,domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.status))]),_c('div',{staticClass:"icon icon-arrow-down"})]),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.input.client,
        mod: 'clients',
        key: 'client',
        input: _vm.fields.client,
        inputId: _vm.fields.client_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('div',{staticClass:"form__row form__row-70"},[_c('div',{staticClass:"form__input"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.type_id),expression:"fields.type_id"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fields, "type_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeType]}},_vm._l((_vm.types),function(type,index){return _c('option',{key:index,domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.type))]),_c('div',{staticClass:"icon icon-arrow-down"})]),_c('div',{staticClass:"form__input"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.select),expression:"fields.select"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fields, "select", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.selects),function(select,index){return _c('option',{key:index,domProps:{"value":select.id}},[_vm._v(" "+_vm._s(select.name)+" ")])}),0),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.view))]),_c('div',{staticClass:"icon icon-arrow-down"})])]),(!_vm.isFreight)?_c('DataInput',{attrs:{"data":{
        data: _vm.fields.dateAdded,
        input: 'dateAdded',
        name: _vm.$root.langText.input.dateAdded,
      }},on:{"resultData":_vm.dataInput}}):_vm._e(),(_vm.quotationsId && this.$route.params.id)?_c('div',[(
          _vm.quotationsId.order_id == 0 &&
          this.$root.add.can_add_order &&
          _vm.quotationsId.id
        )?_c('a',{staticClass:"btn-save btn btn-primary",staticStyle:{"margin-bottom":"20px"},on:{"click":function($event){$event.preventDefault();return _vm.puschOrders($event)}}},[_vm._v(_vm._s(_vm.$root.langText.changes.createFlight))]):_vm._e(),(_vm.quotationsId.order_id !== '0' && _vm.quotationsId.order_id !== '')?_c('a',{staticClass:"btn-save btn btn-primary",staticStyle:{"margin-bottom":"20px"},on:{"click":function($event){$event.preventDefault();return _vm.puschOrders2($event)}}},[_vm._v(_vm._s(_vm.$root.langText.directory.orderOne)+" #"+_vm._s(_vm.quotationsId.order_number)+" "+_vm._s(_vm.$root.langText.from)+" "+_vm._s(_vm._f("date")(_vm.quotationsId.order_date)))]):_vm._e()]):_vm._e(),(_vm.isFreight)?_c('div',[_c('Autocomplete',{attrs:{"data":{
          name: _vm.$root.langText.input.conditions,
          mod: 'all',
          modType: 'conditions',
          key: 'conditions',
          input: _vm.fields.conditions,
          inputId: _vm.fields.conditions_id,
        }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
          name: _vm.$root.langText.input.cargo,
          mod: 'all',
          modType: 'cargo',
          key: 'cargo',
          input: _vm.fields.cargo,
          inputId: _vm.fields.cargo_id,
        }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
          name: _vm.$root.langText.changes.container,
          mod: 'carType',
          key: 'container_type',
          input: _vm.fields.container_type,
          inputId: _vm.fields.container_type_id,
        }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
          name: _vm.$root.langText.route,
          mod: 'all',
          modType: 'route',
          key: 'route',
          input: _vm.fields.route,
          inputId: _vm.fields.route_id,
        }},on:{"result":_vm.autocompleteResult}}),_c('DataInput',{attrs:{"data":{
          data: _vm.fields.readiness,
          input: 'readiness',
          name: _vm.$root.langText.input.valid_to,
        }},on:{"resultData":_vm.dataInput}}),_c('DataInput',{attrs:{"data":{
          data: _vm.fields.valid_to,
          input: 'valid_to',
          name: _vm.$root.langText.input.ready,
        }},on:{"resultData":_vm.dataInput}}),_c('Autocomplete',{attrs:{"data":{
          name: _vm.$root.langText.input.legalEntity2,
          mod: 'legalPersons',
          key: 'legalEntity',
          input: _vm.fields.legalEntity,
          inputId: _vm.fields.legalEntity_id,
        }},on:{"result":_vm.autocompleteResult}}),_c('div',{staticClass:"form__input"},[_c('p',{staticClass:"form__input__textarea-p"},[_vm._v(_vm._s(_vm.fields.specialConditions))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.specialConditions),expression:"fields.specialConditions"}],staticClass:"form__input__textarea",attrs:{"type":"text"},domProps:{"value":(_vm.fields.specialConditions)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "specialConditions", $event.target.value)}}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$root.langText.input.specialConditions))])]),_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$root.langText.input.deliveryWay))]),_c('Autocomplete',{attrs:{"data":{
            name: _vm.$root.langText.input.poo,
            mod: 'city',
            key: 'poo',
            input: _vm.fields.poo,
            inputId: _vm.fields.poo_id,
            img: _vm.id.poo_country_code,
          }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
            name: _vm.$root.langText.input.pol,
            mod: 'city',
            key: 'pol',
            input: _vm.fields.pol,
            inputId: _vm.fields.pol_id,
            img: _vm.id.pol_country_code,
          }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
            name: _vm.$root.langText.input.pod,
            mod: 'city',
            key: 'pod',
            input: _vm.fields.pod,
            inputId: _vm.fields.pod_id,
            img: _vm.id.pod_country_code,
          }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
            name: _vm.$root.langText.input.fd,
            mod: 'city',
            key: 'fd',
            input: _vm.fields.fd,
            inputId: _vm.fields.fd_id,
            img: _vm.id.fd_country_code,
          }},on:{"result":_vm.autocompleteResult}})],1)],1):_vm._e(),_c('h2',[_vm._v(_vm._s(_vm.$root.langText.input.employeesResponsible))]),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.input.managerClients,
        mod: 'team',
        key: 'manager',
        input: _vm.fields.manager,
        inputId: _vm.fields.manager_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.filters.directionHead,
        mod: 'team',
        key: 'leader',
        input: _vm.fields.leader,
        inputId: _vm.fields.leader_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.input.logist,
        mod: 'team',
        key: 'logistician',
        input: _vm.fields.logistician,
        inputId: _vm.fields.logistician_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.input.documentManager,
        mod: 'team',
        key: 'documentManager',
        input: _vm.fields.documentManager,
        inputId: _vm.fields.documentManager_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.input.assistant,
        mod: 'team',
        key: 'assistant',
        input: _vm.fields.assistant,
        inputId: _vm.fields.assistant_id,
      }},on:{"result":_vm.autocompleteResult}}),_c('Autocomplete',{attrs:{"data":{
        name: _vm.$root.langText.other,
        mod: 'team',
        key: 'other',
        input: _vm.fields.other,
        inputId: _vm.fields.other_id,
      }},on:{"result":_vm.autocompleteResult}}),(_vm.$route.params.id)?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm('copy')}}},[_vm._v(_vm._s(_vm.$root.langText.copy))]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm('save')}}},[_vm._v(_vm._s(_vm.$root.langText.save))])]):_c('div',[_c('a',{staticClass:"btn btn-primary btn-save",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm('save')}}},[_vm._v(_vm._s(_vm.$root.langText.save))])]),(_vm.message.popup)?_c('Message',{attrs:{"message":_vm.message},on:{"close":function($event){_vm.message.popup = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }