<template>
  <div>
    <form class="form">
      <h2>{{ $root.langText.info }}</h2>

      <div class="form__row" :class="$route.params.id ? 'form__row-70' : ''">
        <div class="form__input" v-if="$route.params.id">
          <input type="text" disabled v-model="fields.number" />
          <span>{{ $root.langText.input.number }}</span>
        </div>

        <div class="form__input--plagin">
          <DataInput
            :data="{
              data: fields.date,
              input: 'date',
              edit: $root.edit.can_edite_data_orders,
              name: $root.langText.input.date,
            }"
            @resultData="dataInput"
          />
        </div>
      </div>

      <div class="form__input" v-if="$route.params.id">
        <input type="text" disabled v-model="fields.creator_name" />
        <span>{{ $root.langText.input.author }}</span>
      </div>

      <div class="form__input">
        <select v-model="fields.status_id">
          <option v-for="(type, index) in status" :key="index" :value="type.id">
            {{ type.name }}
          </option>
        </select>
        <span>{{ $root.langText.input.status }}</span>
        <div class="icon icon-arrow-down"></div>
      </div>

      <Autocomplete
        :data="{
          name: $root.langText.input.client,
          mod: 'clients',
          key: 'client',
          input: fields.client,
          inputId: fields.client_id,
        }"
        @result="autocompleteResult"
      />

      <div class="form__row form__row-70">
        <div class="form__input">
          <select v-model="fields.type_id" @change="changeType">
            <option
              v-for="(type, index) in types"
              :key="index"
              :value="type.id"
            >
              {{ type.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.type }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>

        <div class="form__input">
          <select v-model="fields.select">
            <option
              v-for="(select, index) in selects"
              :key="index"
              :value="select.id"
            >
              {{ select.name }}
            </option>
          </select>
          <span>{{ $root.langText.input.view }}</span>
          <div class="icon icon-arrow-down"></div>
        </div>
      </div>

      <DataInput
        v-if="!isFreight"
        :data="{
          data: fields.dateAdded,
          input: 'dateAdded',
          name: $root.langText.input.dateAdded,
        }"
        @resultData="dataInput"
      />

      <div v-if="quotationsId && this.$route.params.id">
        <a
          @click.prevent="puschOrders"
          v-if="
            quotationsId.order_id == 0 &&
            this.$root.add.can_add_order &&
            quotationsId.id
          "
          class="btn-save btn btn-primary"
          style="margin-bottom: 20px"
          >{{ $root.langText.changes.createFlight }}</a
        >
        <a
          @click.prevent="puschOrders2"
          v-if="quotationsId.order_id !== '0' && quotationsId.order_id !== ''"
          class="btn-save btn btn-primary"
          style="margin-bottom: 20px"
          >{{ $root.langText.directory.orderOne }} #{{
            quotationsId.order_number
          }}
          {{ $root.langText.from }} {{ quotationsId.order_date | date }}</a
        >
      </div>

      <div v-if="isFreight">
        <Autocomplete
          :data="{
            name: $root.langText.input.conditions,
            mod: 'all',
            modType: 'conditions',
            key: 'conditions',
            input: fields.conditions,
            inputId: fields.conditions_id,
          }"
          @result="autocompleteResult"
        />
        <Autocomplete
          :data="{
            name: $root.langText.input.cargo,
            mod: 'all',
            modType: 'cargo',
            key: 'cargo',
            input: fields.cargo,
            inputId: fields.cargo_id,
          }"
          @result="autocompleteResult"
        />

        <Autocomplete
          :data="{
            name: $root.langText.changes.container,
            mod: 'carType',
            key: 'container_type',
            input: fields.container_type,
            inputId: fields.container_type_id,
          }"
          @result="autocompleteResult"
        />

        <Autocomplete
          :data="{
            name: $root.langText.route,
            mod: 'all',
            modType: 'route',
            key: 'route',
            input: fields.route,
            inputId: fields.route_id,
          }"
          @result="autocompleteResult"
        />

        <DataInput
          :data="{
            data: fields.readiness,
            input: 'readiness',
            name: $root.langText.input.valid_to,
          }"
          @resultData="dataInput"
        />

        <DataInput
          :data="{
            data: fields.valid_to,
            input: 'valid_to',
            name: $root.langText.input.ready,
          }"
          @resultData="dataInput"
        />
        <Autocomplete
          :data="{
            name: $root.langText.input.legalEntity2,
            mod: 'legalPersons',
            key: 'legalEntity',
            input: fields.legalEntity,
            inputId: fields.legalEntity_id,
          }"
          @result="autocompleteResult"
        />
        <div class="form__input">
          <p class="form__input__textarea-p">{{ fields.specialConditions }}</p>
          <textarea
            class="form__input__textarea"
            type="text"
            v-model="fields.specialConditions"
          />
          <span>{{ $root.langText.input.specialConditions }}</span>
        </div>

        <div>
          <h2>{{ $root.langText.input.deliveryWay }}</h2>

          <Autocomplete
            :data="{
              name: $root.langText.input.poo,
              mod: 'city',
              key: 'poo',
              input: fields.poo,
              inputId: fields.poo_id,
              img: id.poo_country_code,
            }"
            @result="autocompleteResult"
          />

          <Autocomplete
            :data="{
              name: $root.langText.input.pol,
              mod: 'city',
              key: 'pol',
              input: fields.pol,
              inputId: fields.pol_id,
              img: id.pol_country_code,
            }"
            @result="autocompleteResult"
          />

          <Autocomplete
            :data="{
              name: $root.langText.input.pod,
              mod: 'city',
              key: 'pod',
              input: fields.pod,
              inputId: fields.pod_id,
              img: id.pod_country_code,
            }"
            @result="autocompleteResult"
          />

          <Autocomplete
            :data="{
              name: $root.langText.input.fd,
              mod: 'city',
              key: 'fd',
              input: fields.fd,
              inputId: fields.fd_id,
              img: id.fd_country_code,
            }"
            @result="autocompleteResult"
          />
        </div>
      </div>

      <h2>{{ $root.langText.input.employeesResponsible }}</h2>
      <Autocomplete
        :data="{
          name: $root.langText.input.managerClients,
          mod: 'team',
          key: 'manager',
          input: fields.manager,
          inputId: fields.manager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.filters.directionHead,
          mod: 'team',
          key: 'leader',
          input: fields.leader,
          inputId: fields.leader_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.logist,
          mod: 'team',
          key: 'logistician',
          input: fields.logistician,
          inputId: fields.logistician_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.documentManager,
          mod: 'team',
          key: 'documentManager',
          input: fields.documentManager,
          inputId: fields.documentManager_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.input.assistant,
          mod: 'team',
          key: 'assistant',
          input: fields.assistant,
          inputId: fields.assistant_id,
        }"
        @result="autocompleteResult"
      />

      <Autocomplete
        :data="{
          name: $root.langText.other,
          mod: 'team',
          key: 'other',
          input: fields.other,
          inputId: fields.other_id,
        }"
        @result="autocompleteResult"
      />

      <!-- <a class="btn-save btn btn-primary" href="#" @click.prevent="sendForm">{{$root.langText.save}}</a> -->
      <div
        style="display: flex; justify-content: space-between"
        v-if="$route.params.id"
      >
        <a
          class="btn btn-secondary"
          href="#"
          @click.prevent="sendForm('copy')"
          >{{ $root.langText.copy }}</a
        >

        <a class="btn btn-primary" href="#" @click.prevent="sendForm('save')">{{
          $root.langText.save
        }}</a>
      </div>
      <div v-else>
        <a
          class="btn btn-primary btn-save"
          href="#"
          @click.prevent="sendForm('save')"
          >{{ $root.langText.save }}</a
        >
      </div>

      <Message
        v-if="message.popup"
        :message="message"
        @close="message.popup = false"
      />
    </form>
  </div>
</template>




<script>
import Autocomplete from "@/components/app/Autocomplete";
import Message from "@/components/app/Message";
import DataInput from "@/components/app/DataInput";

export default {
  props: ["id"],
  data: () => ({
    quotationsId: null,

    fields: {
      id: "",
      number: "",
      date: "",
      creator_name: "",

      client: "",
      client_id: "",
      type_id: "",
      select: "",
      dateAdded: "",
      transit: "",

      conditions: "",
      conditions_id: "",

      cargo: "",
      cargo_id: "",

      container_type: "",
      container_type_id: "",

      route: "",
      route_id: "",

      readiness: "",
      valid_to: "",

      poo: "",
      poo_id: "",

      pol: "",
      pol_id: "",

      pod: "",
      pod_id: "",

      fd: "",
      fd_id: "",

      legalEntity: "",
      legalEntity_id: "",
      specialConditions: "",

      manager: "",
      manager_id: "",
      assistant: "",
      assistant_id: "",
      logistician: "",
      logistician_id: "",
      documentManager: "",
      documentManager_id: "",
      leader: "",
      leader_id: "",
      other: "",
      other_id: "",
      type_direction_color: "#8EB6E3",
      status_id: "1",
    },

    selects: [],
    isFreight: false,
    types: [],
    status: [],

    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },

    //select type
    params: {
      start: 0,
      limit: 20,
    },
  }),
  async created() {
    if (this.$route.params.id) {
      if (this.$root.data.order.info) {
        this.quotationsId = this.$root.data.order.info;
      } else {
        this.quotationsId = this.id;
      }

      this.fields.id = this.quotationsId.id;
      this.fields.creator_name = this.quotationsId.creator_name;
      this.fields.status_id = this.quotationsId.status_id;
      this.fields.number = this.id.number;
      this.fields.date = this.quotationsId.created_at;
      this.fields.client = this.quotationsId.client_name;
      this.fields.client_id = this.quotationsId.client_id;
      this.fields.type_id = this.quotationsId.order_type_id;
      this.fields.type_direction_color = this.quotationsId.type_direction_color;
      this.fields.select = this.quotationsId.order_type_direction_id;
      this.fields.dateAdded = this.quotationsId.date_of_load;

      this.fields.conditions = this.quotationsId.incoterm_name;
      this.fields.conditions_id = this.quotationsId.incoterm_id;

      this.fields.cargo = this.quotationsId.cargo_name;
      this.fields.cargo_id = this.quotationsId.cargo_id;

      this.fields.container_type = this.quotationsId.container_type_name;
      this.fields.container_type_id = this.quotationsId.container_type_id;

      this.fields.route = this.quotationsId.route_name;
      this.fields.route_id = this.quotationsId.route_id;

      this.fields.readiness = this.quotationsId.cargo_ready;
      this.fields.valid_to = this.quotationsId.valid_to;

      this.fields.poo = this.quotationsId.poo_name;
      this.fields.poo_id = this.quotationsId.poo_id;
      this.fields.pooData = this.quotationsId.date_of_poo;

      this.fields.pol = this.quotationsId.pol_name;
      this.fields.pol_id = this.quotationsId.pol_id;
      this.fields.polData = this.quotationsId.date_of_pol;

      this.fields.pod = this.quotationsId.pod_name;
      this.fields.pod_id = this.quotationsId.pod_id;
      this.fields.podData = this.quotationsId.date_of_pod;

      this.fields.fd = this.quotationsId.fd_name;
      this.fields.fd_id = this.quotationsId.fd_id;
      this.fields.fdData = this.quotationsId.date_of_fd;
      this.fields.legalEntity = this.quotationsId.legal_entity_name;
      this.fields.legalEntity_id = this.quotationsId.legal_entity_id;

      this.fields.specialConditions = this.quotationsId.special_conditions;

      // this.fields.transit = this.quotationsId.manager_name
      this.fields.manager = this.quotationsId.manager_name;
      this.fields.manager_id = this.quotationsId.manager_id;
      this.fields.assistant = this.quotationsId.assistant_name;
      this.fields.assistant_id = this.quotationsId.assistant_id;
      this.fields.logistician = this.quotationsId.op_manager_name;
      this.fields.logistician_id = this.quotationsId.op_manager_id;
      this.fields.documentManager = this.quotationsId.doc_support_manager_name;
      this.fields.documentManager_id = this.quotationsId.doc_support_manager_id;
      this.fields.leader = this.quotationsId.head_of_name;
      this.fields.leader_id = this.quotationsId.head_of_id;
      this.fields.other = this.quotationsId.other_manager_name;
      this.fields.other_id = this.quotationsId.other_manager_id;
    } else {
      this.fields.date = new Intl.DateTimeFormat("ru", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(new Date());
    }

    if (this.$root.status.quotation) {
      this.status = this.$root.status.quotation;
    } else {
      const res2 = await this.$store.dispatch("getStatusQ");
      this.status = res2.data.list;
      this.$root.status.quotation = res2.data.list;
    }
    if (this.$root.type) {
      this.types = this.$root.type;
    } else {
      const res = await this.$store.dispatch("getOrdersTypes", this.params);
      this.types = res.data.list;
      this.$root.type = res.data.list;
    }
    if (this.$root.view) {
      this.selects = this.$root.view;
    } else {
      const res3 = await this.$store.dispatch("orderType");
      this.selects = res3.data.list;
      this.$root.view = res3.data.list;
    }
    var app = this;
    this.types.forEach(function (item, index) {
      if (app.fields.type_id == item.id) {
        if (item.tech_name == "") {
          app.isFreight = true;
        } else if(item.tech_name == "rio_auto") {
          app.isFreight = false;
        }
      }
    });
  },

  methods: {
    //autocomplete
    autocompleteResult(data) {
      this.fields[data.input] = data.name;
      this.fields[data.input + "_id"] = data.id;
      if (data.doc_menedger_id) {
        this.fields.documentManager = data.doc_menedger_name;
        this.fields.documentManager_id = data.doc_menedger_id;
      }
    },

    //date
    dataInput(data) {
      this.fields[data.input] = data.date;
    },

    async changeType() {
      var app = this;
      if (this.$route.params.id || this.id.length !== 0) {
        var data = {
          id: this.id.id,
          order_type_id: app.fields.type_id,
        };
        await this.$store.dispatch("updateQuotations", data);
      }
      this.types.forEach(function (item, index) {
        if (app.fields.type_id == item.id) {
          if (item.tech_name == "") {
            app.isFreight = true;
          } else if(item.tech_name == "rio_auto") {
            app.isFreight = false;
          }
        }
      });
      this.$emit("auto", app.isFreight);
    },

    //pusch orders
    puschOrders() {
      this.$root.dataFilling = this.id;
      this.$router.push("/orders/new");
    },

    puschOrders2() {
      this.$router.push({
        name: "AgreementsCreateId",
        params: { id: this.quotationsId.order_id },
      });
    },

    async sendForm(mode) {
      function boolean(val) {
        return val == false ? 0 : 1;
      }
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }

      var data = {
        user_name: this.fields.client || "",
        client_id: this.fields.client_id || "",
        order_type_id: this.fields.type_id,
        order_type_direction_id: this.fields.select || "",
        date_of_load: date(this.fields.dateAdded) || "",

        incoterm_id: this.fields.conditions_id || "",
        cargo_id: this.fields.cargo_id || "",
        container_type_id: this.fields.container_type_id || "",
        route_id: this.fields.route_id || "",

        cargo_ready: date(this.fields.readiness) || "",
        valid_to: date(this.fields.valid_to) || "",

        poo_id: this.fields.poo_id || "",
        pol_id: this.fields.pol_id || "",
        pod_id: this.fields.pod_id || "",
        fd_id: this.fields.fd_id || "",
        legal_entity_id: this.fields.legalEntity_id || "",
        special_conditions: this.fields.specialConditions || "",
        // this.fields.resident = manager_name
        manager_name: this.fields.manager || "",
        manager_id: this.fields.manager_id || "",
        assistant_id: this.fields.assistant_id || "",
        op_manager_id: this.fields.logistician_id || "",
        doc_support_manager_id: this.fields.documentManager_id || "",
        head_of_name: this.fields.leader || "",
        head_of_id: this.fields.leader_id || "",
        other_manager_id: this.fields.other_id || "",
        status_id: this.fields.status_id || "",
      };
      if (this.$route.params.id && mode !== "copy") {
        data.id = this.quotationsId.id;
        if (this.$root.data.order.carrier) {
          await this.$store.dispatch(
            "updateQuotations",
            this.$root.data.order.carrier
          );
        }
        if (this.$root.data.order.customer) {
          await this.$store.dispatch(
            "updateQuotations",
            this.$root.data.order.customer
          );
        }
        var update = await this.$store.dispatch("updateQuotations", data);
        this.$root.data.counterparties.info = "";
        this.$root.data.counterparties.customer = "";
        this.$root.data.counterparties.carrier = "";
        this.$emit("updateData");
        //messedge
        if (update.data == "ok") {
          this.message.status = update.data;
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.save;
        } else {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.error;
        }
      } else {
        if (mode !== "copy") {
          var mod = "quotations";
          let app = this;

          if (this.quotationsId) {
            data.special_conditions =
              this.quotationsId.special_conditions || "";
            data.container_type_id = this.quotationsId.container_type_id || "";
            data.carrier_id = this.quotationsId.carrier_id || "";
            data.carrier_manager_id =
              this.quotationsId.carrier_manager_id || "";
            data.driver_id = this.quotationsId.driver_id || "";
            data.truck_id = this.quotationsId.truck_id || "";
            data.trailer_id = this.quotationsId.trailer_id || "";
            data.lifting_capacity = this.quotationsId.lifting_capacity || "";
            data.volume = this.quotationsId.volume || "";
            data.legal_entity_id = this.quotationsId.legal_entity_id || "";
            data.special_conditions =
              this.quotationsId.special_conditions || "";
            data.rate_carrier = this.quotationsId.rate_carrier || "";
            data.border_crossing_id =
              this.quotationsId.border_crossing_id || "";
            data.is_overload = boolean(this.quotationsId.is_overload) || "";

            //если есть перегрузка
            if (this.quotationsId.is_overload) {
              data.overload_city_id = this.quotationsId.overload_city_id || "";
              data.overload_date = date(this.quotationsId.overload_date) || "";

              data.main_carrier2_id = this.quotationsId.main_carrier2_id || "";
              data.carrier2_id = this.quotationsId.carrier2_id || "";
              data.carrier_manager2_id =
                this.quotationsId.carrier_manager2_id || "";
              data.driver_id2 = this.quotationsId.driver_id2 || "";
              data.truck_id2 = this.quotationsId.truck_id2 || "";
              data.trailer_id2 = this.quotationsId.trailer_id2 || "";
              data.lifting_capacity2 =
                this.quotationsId.lifting_capacity2 || "";
              data.volume2 = this.quotationsId.volume2 || "";
              data.legal_entity2_id = this.quotationsId.legal_entity2_id || "";
              data.rate_carrier2 = this.quotationsId.rate_carrier2 || "";
            }
          }
          var createNew = await this.$store.dispatch("createQuotations", data);
          var newId = createNew.data.id;

          
          if (this.quotationsId) {
            if (this.quotationsId.quote_services && this.quotationsId.quote_services.length > 0) {
              for (const item of this.quotationsId.quote_services) {
                var data = item;
                delete data.id;
                delete data.quotes_id;
                data.quotes_id = newId;
                await this.$store.dispatch("servicesAddQuotations", data);
                data = [];
              }
            }
            //если клиента не меняли
            if (this.quotationsId.client_id == this.fields.client_id) {
              // console.log(this.id, this.quotationsId);
              if (this.quotationsId.quotes_directions !== 0) {
                let arr = this.quotationsId.quotes_directions;
                arr.forEach(function (item, index) {
                  var data3 = {
                    date_delivery_from: date(item.date_delivery_from) || "",
                    date_delivery_to: date(item.date_delivery_to) || "",
                    shipment_date_from: date(item.shipment_date_from) || "",
                    shipment_date_to: date(item.shipment_date_to) || "",
                    contractor_id: item.contractor_id || "",
                    poo_id: item.poo_id || "",
                    loading_address: item.loading_address || "",
                    customs_address_from: item.customs_address_from || "",
                    fd_id: item.fd_id || "",
                    unloading_address: item.unloading_address || "",
                    customs_address_to: item.customs_address_to || "",
                    cargo: item.cargo || "",
                    download_method_id: item.download_method_id || "",
                    legal_entity_id: item.legal_entity_id || "",
                    add_to_templates: boolean(item.add_to_templates),
                    quote_id: newId || "",
                  };
                  app.$store.dispatch("createDirectionsQ", data3);
                });
              }

              if (this.quotationsId.customer_freight !== 0) {
                let arr = this.quotationsId.customer_freight;
                arr.forEach(function (item, index) {
                  var data = {
                    service_id: item.service_id || "",
                    comment: item.comment || "",
                    sum: item.sum || "",
                    currency_id: item.currency_id || "",
                    date: date(item.date) || "",
                    quote_id: newId,
                  };

                  const create = app.$store.dispatch("createCustomerFreight", {
                    data,
                    mod,
                  });
                });
              }

              if (this.quotationsId.kickbacks_customer !== 0) {
                let arr = this.quotationsId.kickbacks_customer;
                arr.forEach(function (item, index) {
                  var data = {
                    contPerson_str: item.contPerson_str,
                    contPerson_id: item.contPerson_id,

                    percent: item.percent,
                    card_number: item.card_number,
                    date: date(item.date),
                    sum: item.sum,
                    currency_id: item.currency_id,
                    exchange_rate: item.exchange_rate,
                    quote_id: newId,
                  };

                  const create = app.$store.dispatch("createKickbacks", {
                    data,
                    mod,
                  });
                });
              }
            }

            if (createNew.data.status == "error") {
              this.message.status = "error";
              this.message.popup = true;
              this.message.message = this.$root.langText.changes.fillIn;
            } else {
              this.$router.push({
                name: "QuotationsCreateId",
                params: { id: createNew.data.id },
              });
            }
          } else{
            if (createNew.data.status == "error") {
              this.message.status = "error";
              this.message.popup = true;
              this.message.message = this.$root.langText.changes.fillIn;
            } else {
              this.$router.push({
                name: "QuotationsCreateId",
                params: { id: createNew.data.id },
              });
            }
          }
        } else {
          this.id.id = 0;
          this.fields.id = 0;
          var date = new Intl.DateTimeFormat("ru", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }).format(new Date());
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          this.fields.date = year + "-" + month + "-" + day;
          (this.id.carrier_freight = []),
            (this.id.carrier_freight2 = []),
            (this.id.kickbacks_carrier = []),
            (this.id.kickbacks_carrier2 = []),
            (this.id.requests_carrier = []),
            (this.id.requests_carrier2 = []),
            // this.id.orders_directions = [],
            (this.id.requests_clients = []),
            this.$router.push("/quotes/new");
          return;
        }
      }
    },
  },

  beforeDestroy() {
    //format date
    //format date
    function dateSave(input, key) {
      function date(date) {
        if (date) {
          var year = date.substr(6, 4);
          var day = date.substr(0, 2);
          var month = date.substr(3, 2);
          var resultDate =
            new Date(year + "-" + month + "-" + day).getTime() / 1000;
          return resultDate;
        }
      }
      if (input) {
        if (
          input == "0000-00-00 00:00:00" ||
          input == null ||
          input == "0000-00-00" ||
          input == ""
        ) {
        } else {
          var date = new Date(date(input) * 1000);
          var newdate =
            date.getMonth() +
            1 +
            "." +
            date.getDate() +
            "." +
            date.getFullYear();
          data[key] = newdate;
        }
      }
    }

    var data = {
      client_name: this.fields.client || "",
      client_id: this.fields.client_id || "",
      creator_name: this.fields.creator_name || "",
      order_type_id: this.fields.type_id,
      order_type_direction_id: this.fields.select || "",
      manager_name: this.fields.manager || "",
      manager_id: this.fields.manager_id || "",
      assistant_id: this.fields.assistant_id || "",
      assistant_name: this.fields.assistant || "",
      op_manager_id: this.fields.logistician_id || "",
      op_manager_name: this.fields.logistician || "",
      doc_support_manager_id: this.fields.documentManager_id || "",
      doc_support_manager_name: this.fields.documentManager || "",
      head_of_name: this.fields.leader || "",
      head_of_id: this.fields.leader_id || "",
      other_manager_id: this.fields.other_id || "",
      other_manager_name: this.fields.other || "",
      status_id: this.fields.status_id || "",

      incoterm_name: this.fields.conditions,
      incoterm_id: this.fields.conditions_id,
      cargo_name: this.fields.cargo,
      cargo_id: this.fields.cargo_id,

      container_type_name: this.fields.container_type,
      container_type_id: this.fields.container_type_id,

      route_name: this.fields.route,
      route_id: this.fields.route_id,

      poo_name: this.fields.poo,
      poo_id: this.fields.poo_id,
      // this.fields.pooData = this.quotationsId.date_of_poo;

      pol_name: this.fields.pol,
      pol_id: this.fields.pol_id,
      // this.fields.polData = this.quotationsId.date_of_pol;

      pod_name: this.fields.pod,
      pod_id: this.fields.pod_id,
      // this.fields.podData = this.quotationsId.date_of_pod;

      fd_name: this.fields.fd,
      fd_id: this.fields.fd_id,
      // this.fields.fdData = this.quotationsId.date_of_fd;

      legal_entity_name: this.fields.legalEntity,
      legal_entity_id: this.fields.legalEntity_id,

      special_conditions: this.fields.specialConditions,
    };

    dateSave(this.fields.date, "created_at");
    dateSave(this.fields.dateAdded, "date_of_load");
    dateSave(this.fields.readiness, "cargo_ready");
    dateSave(this.fields.valid_to, "valid_to");

    if (this.id.length !== 0) {
      data.id = this.id.id;
      data.order_id = this.id.order_id || "";
      data.order_number = this.id.order_number || "";
      data.order_date = this.id.order_date || "";
    }

    this.$root.data.order.info = data;
  },
  components: {
    Autocomplete,
    Message,
    DataInput,
  },
};
</script>


