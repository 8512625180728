<template>
  <div class="container">
    <div
      v-if="
        $root.app == 'SoftProCrmAppRioTransTest' ||
        $root.app == 'SoftProCrmAppRioTrans'
      "
    >
      <div class="tabs" :class="status ? '' : 'hide'" v-if="quotationsId">
        <ul class="tabs__caption" v-if="$route.params.id">
          <li
            @click="activetab = 1"
            v-bind:class="[activetab === 1 ? 'active' : '']"
          >
            {{ $root.langText.general }}
          </li>
          <li
            v-if="!isFreight"
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
          >
            {{ $root.langText.input.client }}
          </li>
          <li
            v-if="!isFreight"
            @click="activetab = 3"
            v-bind:class="[activetab === 3 ? 'active' : '']"
          >
            {{ $root.langText.input.carrier }}
          </li>
          <li
            v-if="quotationsId && isFreight"
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
          >
            {{ $root.langText.input.titleService }}
            <span
              v-if="
                quotationsId.quote_services &&
                quotationsId.quote_services.length !== '0'
              "
              >({{ quotationsId.quote_services.length }})</span
            >
          </li>
        </ul>

        <!-- tab 1 -->
        <div
          class="tabs__content"
          v-if="activetab === 1 && quotationsId !== null"
        >
          <QuotationsInfo1
            @auto="autoUpdate"
            :id="quotationsId"
            @updateData="updateData"
          />
        </div>

        <div v-if="isFreight">
          <!-- tab 2 -->
          <div class="tabs__content" v-if="activetab === 2">
            <QuotationsServices :id="quotationsId" @updateData="updateData" />
          </div>
        </div>

        <div v-else>
          <!-- tab 2 -->
          <div class="tabs__content" v-if="activetab === 2">
            <QuotationsClient1 :id="quotationsId" @updateData="updateData" />
          </div>

          <!-- tab 3 -->
          <div class="tabs__content" v-if="activetab === 3">
            <QuotationsCarrier1 :id="quotationsId" @updateData="updateData" />
          </div>
        </div>

        <a @click.prevent="push()" v-if="update" class="add-chat"></a>
      </div>
    </div>

    <div v-else>
      <div class="tabs" :class="status ? '' : 'hide'" v-if="quotationsId">
        <ul class="tabs__caption" v-if="$route.params.id">
          <li
            @click="activetab = 1"
            v-bind:class="[activetab === 1 ? 'active' : '']"
          >
            {{ $root.langText.general }}
          </li>
          <li
            v-if="quotationsId && $root.app !== 'SoftProCrmAppVedanta'"
            @click="activetab = 2"
            v-bind:class="[activetab === 2 ? 'active' : '']"
          >
            {{ $root.langText.input.titleService }}
            <span
              v-if="
                quotationsId.quote_services &&
                quotationsId.quote_services.length !== '0'
              "
              >({{ quotationsId.quote_services.length }})</span
            >
          </li>
          <!-- <li @click="activetab=3" v-bind:class="[ activetab === 3 ? 'active' : '' ]">Перевізник</li> -->
        </ul>

        <!-- tab 1 -->
        <div
          class="tabs__content"
          v-if="activetab === 1 && quotationsId !== null"
        >
          <QuotationsInfoOther :id="quotationsId" @updateData="updateData" />
        </div>

        <!-- tab 2 -->
        <div class="tabs__content" v-if="activetab === 2">
          <QuotationsServices :id="quotationsId" @updateData="updateData" />
        </div>

        <a @click.prevent="push()" v-if="update" class="add-chat"></a>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.decor-line {
  margin: 20px 0;
}
</style>

<script>
import QuotationsInfoOther from "@/components/quotationsOther/QuotationsInfo";
import QuotationsServices from "@/components/quotationsOther/QuotationsServices";
import QuotationsInfo1 from "@/components/quotations/QuotationsInfo";
import QuotationsClient1 from "@/components/quotations/QuotationsClient";
import QuotationsCarrier1 from "@/components/quotations/QuotationsCarrier";

export default {
  data: () => ({
    activetab: 1,
    quotationsId: null,
    status: true,
    update: false,
    quotationsLength: "",
    isFreight: false,
    //Title
    globalTitle: [{ title: "Нове котирування", info: "" }],
  }),

  watch: {
    async $route(to, from) {
      let app = this;
      app.activetab = 1;
      this.createUpdatePage();
    },
  },

  async mounted() {
    this.createUpdatePage();
  },

  methods: {
    async createUpdatePage() {
      if (this.$route.params.id) {
        this.update = true;
        const id = this.$route.params.id;
        const res = await this.$store.dispatch("getQuotationsId", id);
        this.quotationsId = res.data.detail;
        if (this.quotationsId) {
          this.status = this.quotationsId.canEdit;
          this.isFreight = this.quotationsId.isFreight == "1" ? true : false;
          this.$root.data.isFreight = this.isFreight;

          //tehname
          this.isFreight = this.quotationsId.order_type_tech_name == "rio_auto" ? false : true;
          // end tehname

          //Title
          this.$root.title = this.globalTitle;
          if (
            this.$root.app == "SoftProCrmAppRioTransTest" ||
            this.$root.app == "SoftProCrmAppRioTrans"
          ) {
            this.globalTitle[0].title =
              this.$root.langText.directory.quotes +
              " №" +
              this.quotationsId.number;
          } else {
            this.$root.title[0].title =
              this.$root.langText.directory.quotesOther +
              " №" +
              this.quotationsId.number;
          }
          this.$root.title[0].info =
            this.$root.langText.from +
            " " +
            new Intl.DateTimeFormat("ru", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }).format(
              new Date(this.quotationsId.created_at.replace(/-/g, "/"))
            );
        }
      } else {
        this.update = false;
        this.quotationsId = [];
        //title
        if (
          this.$root.app == "SoftProCrmAppRioTransTest" ||
          this.$root.app == "SoftProCrmAppRioTrans"
        ) {
          this.globalTitle[0].title =
            this.$root.langText.new +
            " " +
            this.$root.langText.directory.quotes.toLowerCase();
        } else {
          this.globalTitle[0].title =
            this.$root.langText.new +
            " " +
            this.$root.langText.directory.quotesOther.toLowerCase();
        }
        this.$root.title = this.globalTitle;
      }
    },

    push() {
      const id = this.quotationsId.id;
      const type = "quote";

      this.$router.push({
        name: "ChatMessage",
        params: { id: id + "&" + type },
      });
    },
    autoUpdate(data) {
      this.isFreight = data;
    },

    async updateData() {
      const id = this.$route.params.id;
      const res = await this.$store.dispatch("getQuotationsId", id);
      this.quotationsId = res.data.detail;
    },
  },

  destroyed() {
    this.$root.data.order.info = "";
    this.$root.data.order.customer = "";
    this.$root.data.order.carrier = "";
  },

  components: {
    QuotationsInfoOther,
    QuotationsServices,
    QuotationsInfo1,
    QuotationsClient1,
    QuotationsCarrier1,
  },
  metaInfo() {
    var title = this.$root.langText.directory.quotesOther;
    if (
      this.$root.app == "SoftProCrmAppRioTransTest" ||
      this.$root.app == "SoftProCrmAppRioTrans"
    ) {
      title = this.$root.langText.directory.quotes;
    }
    return {
      title: this.$title(title),
    };
  },
};
</script>