<template>
  <div class="form">

    <p class="info_containers__title">{{$root.langText.total}}:</p>
      <ul class="info_containers">
        <li>
          <span>{{$root.langText.changes.income}}</span>
          <b>{{services_income.toFixed(0)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.consumption}}</span>
          <b>{{services_outgo.toFixed(0)}}</b>
        </li>
        <li>
          <span>{{$root.langText.changes.profit}}</span>
          <b>{{services_profit.toFixed(0)}}</b>
        </li>
      </ul>

        <div class="form__tab">
          <ul>
            <li 
              v-for="(item, index) in currency" 
              :key="index"
              :value="item.id"
              :class="currencyActive == item.id ? 'active' : ''"
              @click="saveCurrency(item.id, $event), currencyActive = item.id"
              >
              {{item.name}}
            </li>
          </ul>
        </div>
    
  
    <Accordion 
      :data="id" 
      :name="{mode: 'services', bd_name: 'quote_services', modPage: 'quotations'}" 
      :modCreate="'quote'" 
      :canEdit="canEdit"
      @update="$emit('updateData')" />  
  </div>
</template>


<style lang="scss">

</style>



<script>
import Accordion from '@/components/agreementsOther/Accordion'

export default {
  props: ['id'],
  data: () => ({
    canEdit: true,
    currency: '',
    currencyActive: 0,
    services_income: 0,
    services_outgo: 0,
    services_profit: 0,
  }),

  watch:{
    id: function(){
      this.updateData(false);
    }
  },


  
  async created() {
    this.canEdit = this.id.canEdit;
    //base Currency
    const currency = await this.$store.dispatch('getCurrency', {start: 0, limit: 50,});
    this.currency = currency.data.list;

    if(this.id.total_sums_currency_id > 0){
      this.currencyActive = this.id.total_sums_currency_id;
    } else{
      this.currencyActive = this.currency[0].id;
    }
    this.updateData(false);
  },
  methods: {
    async saveCurrency(data, e){
      if(document.querySelector(".form__tab .active")){
        document.querySelector(".form__tab .active").classList.remove("active");
      }
      e.target.className = 'active';
      await this.$store.dispatch('updateQuotations', {id: this.id.id, total_sums_currency_id: data});
      this.$emit('updateData');
      this.updateData(true);
    },


    async updateData(click){
      var app = this;
      app.services_income = 0;
      app.services_outgo = 0;

      app.id.quote_services.forEach(async function(item2, index){
        var currencyRealIncom;
        var currencyRealOutgo;
        var currencyTotal = 1;
        var is_reversed = 0;
        var sumIncom = +item2.sum_income;
        var sumOutgo = +item2.sum_outgo;

        app.currency.forEach(function(item, index){
          if(item.id == app.currencyActive){
            currencyTotal = item.exchange_rate;
          }
          if(item.id == item2.currency_income){
            currencyRealIncom = item.exchange_rate;
          }
          if(item.id == item2.currency_id){
            currencyRealOutgo = item.exchange_rate;
          }
          if(item.is_reversed == 1){
            is_reversed = 1
          }
        });

        if (is_reversed == "0") {
          if(sumIncom){
              var sum_income2 = sumIncom / currencyRealIncom * currencyTotal;
            app.services_income += sum_income2
          }
          if(sumOutgo){
              var sum_outgo2 = sumOutgo / currencyRealOutgo * currencyTotal;
            app.services_outgo += sum_outgo2
          }
        } else{
          if(sumIncom){
              var sum_income2 = sumIncom * currencyRealIncom / currencyTotal;
            app.services_income += sum_income2
          }
          if(sumOutgo){
              var sum_outgo2 = sumOutgo * currencyRealOutgo / currencyTotal;
            app.services_outgo += sum_outgo2
          }
        }

        //update services
        if(click){
          var data = {
            id: item2.id,
            sum_outgo2: sum_outgo2,
            exchange_rate_out: sum_outgo2 / sumOutgo
          }
          if(sumIncom){
            data.sum_income2 = sum_income2;
            data.exchange_rate_in = sum_income2 / sumIncom;
          }
          if(sumOutgo){
            data.sum_outgo2 = sum_outgo2;
            data.exchange_rate_out = sum_outgo2 / sumOutgo;
          }
          if(!sumOutgo && !sumIncom){} else{
            await app.$store.dispatch('servicesUpdateQuotations', data);
          }
        }
      });
      app.services_profit = app.services_income - app.services_outgo;
    }
  },


  components: {
    Accordion
  }
}
</script>