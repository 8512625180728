<template>
  <form class="form">

    <div class="form__input">
      <input 
        type="text"
        v-model="specialConditions"
      >
      <span>{{$root.langText.input.specialConditions}}</span>
    </div>

    <div class="form__input">
      <input 
        type="text"
        v-model="comments"
      >
      <span>{{$root.langText.input.comments}}</span>
    </div>

    <a class="btn-save btn btn-primary" v-if="canEdit" style="margin-top: -15px; margin-bottom: 15px" @click.prevent="sendForm">{{$root.langText.save}}</a>

    <div class="block-slide-toggle"
      v-for="(accordion, index) in accordionName"
      :key="index"
    >
      <Accordion 
        :data="id"
        :application="{
          driver: id.driver_name, 
          driver_id: id.driver_id,
          truck: id.truck_name, 
          truck_id: id.truck_id,
          main_carrier_id: id.main_carrier_id,
        }"
        :canEdit="canEdit"
       :name="accordionName[index]" :modCreate="'quotation'" :modType="1" @update="$emit('updateData')" />
      <div class="decor-line"></div>
    </div>



    <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
  </form>
</template>



<style lang="scss">
 
</style>


<script>
import Accordion from '@/components/agreements/Accordion'
import Message from "@/components/app/Message"

export default {
  props: ['id'],
  data: () => ({
    agreementsId: null,
    canEdit: true,
    

    
    accordionName: [
      {name: 'Маршрут', mode: 'directions', bd_name: 'quotes_directions'},
      {name: 'Фрахт замовника', mode: 'freight', bd_name: 'customer_freight'},
      {name: 'ОТК замовника', mode: 'kickbacks', bd_name: 'kickbacks_customer'},
      {name: 'Заявка від замовника', mode: 'requests', bd_name: 'requests_clients'},
      
    ],

    specialConditions: '',
    comments: '',
    
    
    //message
    message: {
      status: '',
      popup: false,
      message: ''
    },

  }),



  async created() {
    this.accordionName[0].name = this.$root.langText.route
    this.accordionName[1].name = this.$root.langText.customerFreight
    this.accordionName[2].name = this.$root.langText.kickbacksCustomer
    this.accordionName[3].name = this.$root.langText.requestsClients


    if(this.$route.params.id){
      this.canEdit = this.id.canEdit

      if(this.$root.add.is_dispatcher){
        this.accordionName.splice(1, 3)
      }
      if(this.$root.data.order.customer){
        this.agreementsId = this.$root.data.order.customer
      } else{
        this.agreementsId = this.id
      }
      
      this.specialConditions = this.agreementsId.special_conditions
      this.comments = this.agreementsId.comment
    }

  },
  methods: {



    async sendForm(){
     
      var dataOrder = {
        special_conditions: this.specialConditions || '',
        comment: this.comments || '',
        id: this.agreementsId.id
      }
      if(this.$route.params.id){
        if(this.$root.data.order.carrier){
          await this.$store.dispatch('updateQuotations', this.$root.data.order.carrier);
        }
        if(this.$root.data.order.info){
          var old = this.$root.data.order.info.created_at
          this.$root.data.order.info.created_at = this.$root.data.order.info.created_at ? new Date(this.$root.data.order.info.created_at).getTime() / 1000 : ''
          await this.$store.dispatch('updateQuotations', this.$root.data.order.info);
          this.$root.data.order.info.created_at = old
        }
        var updateOrder = await this.$store.dispatch('updateQuotations', dataOrder);
        this.$root.data.counterparties.info = ''
        this.$root.data.counterparties.customer = ''
        this.$root.data.counterparties.carrier = ''
        this.$emit('updateData')
        
        //messedge
        if(updateOrder.data == 'ok'){
          this.message.status = updateOrder.data
          this.message.popup = true
          this.message.message = this.$root.langText.changes.save
        } else{
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.error
        }
      }
    }
  },

  beforeDestroy() {
    var data = {
      special_conditions: this.specialConditions || '',
      comment: this.comments || '',
    }
    if(this.id){
      data.id = this.id.id
    }
    this.$root.data.order.customer = data
  },

  components: {
    Accordion, Message
  }
}
</script>